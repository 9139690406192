const $mosaic_images = document.querySelectorAll('.act-mosaic-gallery .image');
const $close_slider_popups = document.querySelectorAll('.act-popup-mosaic-slider__btn-close');

var previousElement = null;
if ($mosaic_images) {
    $mosaic_images.forEach(function ($mosaic_image) {
        $mosaic_image.addEventListener('click', (ele) => {
            ele.preventDefault();
            previousElement = (document.activeElement || document.body);
            var current_image = ele.target.getAttribute('data-image');
            var slider_id = ele.target.getAttribute('data-slider');
            var $current_slider = document.getElementById(slider_id);
            var $current_slides = document.querySelectorAll('#' + slider_id + ' button[data-nav="' + current_image + '"]');
            $current_slides.forEach(function ($current_slide) {
                $current_slide.click();
            });
            $current_slider.classList.add('act-popup-mosaic-slider--visible');
            $current_slider.getElementsByClassName( "act-popup-mosaic-slider__btn-close" )[0].focus();

        });
    });
}

if ($close_slider_popups) {
    $close_slider_popups.forEach(function ($close_slider_popup) {
        $close_slider_popup.addEventListener('click', (ele) => {
            var $current_slider = ele.target.closest('.act-popup-mosaic-slider');
            $current_slider.classList.remove('act-popup-mosaic-slider--visible');
            previousElement.focus();
            previousElement = null;
        });
    });
}

document.addEventListener('click', function(event) {
  if (event.target.classList.contains('act-popup-mosaic-slider')) {
    event.target.classList.remove('act-popup-mosaic-slider--visible');
    previousElement.focus();
    previousElement = null;
  }
});
